#root {
    height: 100vh;
    width: 100vw;
}

.App {
    width: 100vw;
    height: 100vh;
}

#custom-footer div {
    width: 50%;
}

.image-wrapper {
    /* perspective: 1000px; */
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
    /* transform: rotateY(-30deg) rotateZ(4deg); */
}

* {
    -ms-overflow-style: none;
}
::-webkit-scrollbar {
    display: none;
}
